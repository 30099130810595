// src/router/index.jsx
import { createBrowserRouter } from "react-router-dom";
import Index from "./pages/Index";
import Welcome from "./pages/Welcome";

const router = createBrowserRouter([
  {
    path: "/main",
    element: <Index />,
  },
  {
    path: "/",
    element: <Welcome />,
  },
]);

export default router;
