import React from "react";

function WaterfallWidget({ data }) {
  console.log({ WaterfallWidget: data });
  return (
    <div className="waterfall-flow">
      {data.data.map((item, index) => (
        <a key={index} className="flow-item" i="2098" href={item?.url} rel="noreferrer" target="_blank">
          <div className="flow-img-wrap">
            <img className="flow-img" src={item?.img} alt="" />
            {/* <div className="flow-ab">
              <div className="sound">
                <img src="star-icon.svg" alt="" />
                <span>99%满意</span>
              </div>
              <div className="praise">
                <img src="zan-icon.svg" alt="" />
                <span>3041</span>
              </div>
            </div> */}
          </div>
          <div className="flow-text-wrap">
            <div className="flow-text1">
              <div className="g-name">
                {item?.name} <span className="g-info"> {item?.info} </span>
              </div>
            </div>
            <div className="flow-tips">
              <div>{item?.age}岁</div>
              <div>{item?.job} </div>
              <div>{item?.online} </div>
            </div>
            <div className="flow-text2 text2">{item?.service}</div>
            <div className="g-pic">
              <div className="g-l-bg g-bg">{item?.onePrice} </div>
              <div className="g-r-bg g-bg">{item?.nightPrice}</div>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
}

export default WaterfallWidget;
