import "../App.css";
import axiosInstance from "../utils/http";
import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import LayoutWidget from "../components/LayoutWidget";
import "swiper/css";

function Index() {
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const swiperRef = useRef(null);

  useEffect(() => {
    axiosInstance
      .get("/api/data")
      .then((res) => {
        console.log(res);
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // 处理 Tab 点击
  const handleTabClick = (index) => {
    setActiveTab(index);
    if (swiperRef && swiperRef.current) {
      swiperRef.current.swiper.slideTo(index); // 触发 Swiper 切换到对应的内容
    }
  };

  return (
    <div className="app">
      {/* 导航栏 */}
      <div className="my-sticky">
        <div className="navtop">
          {data.map((item, index) => (
            <div onClick={() => handleTabClick(index)} className="navtop-item" data-index="0">
              <div className={`navtop-item-title ${activeTab === index ? "active" : ""}`}>{item.title}</div>
            </div>
          ))}
        </div>
      </div>

      <Swiper
        ref={swiperRef}
        onSlideChange={(swiper) => setActiveTab(swiper.activeIndex)} // 滑动时更新激活的 Tab
        className="swiper-container"
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            {item.data.map((e, index) => (
              <LayoutWidget data={e} key={index} />
            ))}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Index;
