import React from "react";

function LfAdWidget({ data }) {
  return (
    <div className="top-ad">
      {data.data.map((item, index) => (
        <a key={index} className="top-ad-item" href={item.url} rel="noreferrer" target="_blank" i={index}>
          <div className="top-ad-item-1">
            <img className="top-ad-img" src={item.img} alt={item.name} />
            <div className="top-ad-content">
              <div className="top-ad-title">{item.name}</div>
              <div className="top-ad-text">{item.description}</div>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
}

export default LfAdWidget;
