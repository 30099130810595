import React from "react";

function AppWidget({ data }) {
  return (
    <div className="gftj-wrap">
      <div className="gftj-title">{data.title}</div>
      <div className="gftj-content">
        {data.data.map((item, index) => (
          <a className="gftj-item" rel="noreferrer" key={index} target="_blank" href={item.url} i={index}>
            <img className="gftj-img" src={item.img} alt={item.title} />
            <div className="text">{item.name}</div>
          </a>
        ))}
      </div>
    </div>
  );
}
export default AppWidget;
