import React, { Component } from "react";

function LiveWidget({ data }) {
  return (
    <div className="live-wrap">
      {data.data.map((item, index) => (
        <a key={index} href={item.url} rel="noreferrer" target="_blank" className="live-item ">
          <div className="live-item-wrap">
            <img className="live-item-img" src={item.img} alt="" />
            <div className="center-icon">
              <img src="/live.png" alt="" />
            </div>
            <div className="live-tab">真人认证</div>
            <img src="/state_icon_online.png" className="onLine"></img>
          </div>

          <div className="bt-info">
            <div className="live-left">{item.name}</div>
            <div className="live-watching">
              <div className="live-count">38639人</div>
              <div>正在观看</div>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
}
export default LiveWidget;
